<template>
  <v-card width="500px">
    <v-row
      no-gutters
      class="pa-5"
    >
      <v-file-input
        v-model="files"
        color="pohjala_blue"
        counter
        label="Üleslaetavad dokumendid"
        multiple
        outlined
        dense
        :accept="dialogPayload.accept || ''"
        :rules="dialogPayload.rules || []"
        placeholder="Vali üleslaadimiseks dokumendid"
        :prepend-icon="mdiPaperclip"
        :show-size="1000"
      >
        <template #selection="{ index, text }">
          <v-chip
            v-if="index < 2"
            color="pohjala_blue"
            dark
            label
            small
          >
            {{ text }}
          </v-chip>

          <span
            v-else-if="index === 2"
            class="text-overline grey--text text--darken-3 mx-2"
          >
            +{{ files.length - 2 }} dokumenti
          </span>
        </template>
      </v-file-input>
    </v-row>
    <v-toolbar
      flat
      color="transparent"
    >
      <v-spacer />
      <v-btn
        text
        color="primary"
        @click="closeDialog"
      >
        Loobu
      </v-btn>
      <v-btn
        color="primary"
        @click="onSaveClick"
      >
        Salvesta
      </v-btn>
    </v-toolbar>
  </v-card>
</template>
<script>
import { ref } from '@vue/composition-api';
import { mdiPaperclip } from '@mdi/js';
import storageModule from '@/store/modules/storage';
import dialogModule from '@/store/modules/dialog';

const vectorIcons = { mdiPaperclip };
export default {
  setup() {
    const { dialogPayload, closeDialog } = dialogModule();
    const { uploadFileAsPromise, uploadQueue, fetchDirectoryItem } = storageModule();
    const files = ref([]);
    const onSaveClick = () => {
      const directoryItem = dialogPayload.value && dialogPayload.value.directoryItem;
      const rootpath = directoryItem.fullPath.split('/')[0];
      files.value.forEach((f) => {
        uploadFileAsPromise({
          file: f,
          directory: directoryItem.fullPath,
          onFinalize: () => {
            if (directoryItem.onFinalize) {
              directoryItem.onFinalize({
                item: directoryItem, // storage directory item
                rootpath,
              });
            } else {
              fetchDirectoryItem({
                item: directoryItem, // storage directory item
                rootpath,
              });
            }
          },
        });
      });
      closeDialog();
    };
    return {
      ...vectorIcons,
      files,
      onSaveClick,
      uploadQueue,
      dialogPayload,
      closeDialog,
    };
  },
};
</script>
